import React from "react";
import { graphql } from "gatsby";
import { TOptions } from "i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import i18n from "../i18n/i18n";
import Layout from "../components/Layout";
import PageStripe from "../components/PageStripe";
import SEO from "../components/SEO";

const Projects = ({ pageContext, data }) => {
  const { projects } = data;
  const sectionWidth = "w-4/5 md:w-8/12 lg:w-7/12 2xl:w-5/12 m-auto";
  const t = (key: string, options?: TOptions) => {
    return i18n.t(key, { ...options, lng: pageContext.lang });
  };
  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={t("projectPage.SEO.title")}
        description={t("projectPage.SEO.description")}
        pageContext={pageContext}
      ></SEO>
      <PageStripe
        name={t("projectPage.title")}
        bgImage="projects.jpg"
      ></PageStripe>
      <section
        className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-5 gap-x-20 ${sectionWidth} mt-20`}
      >
        {projects.edges.map((edge: any, key: number) => {
          return (
            <GatsbyImage
              key={key}
              image={getImage(edge.node.childImageSharp.gatsbyImageData) as any}
              alt={edge.node.name}
              className="flex justify-center items-center m-auto h-full"
            />
          );
        })}
      </section>
    </Layout>
  );
};

export default Projects;

export const query = graphql`
  query {
    projects: allFile(filter: { relativeDirectory: { regex: "/projects/" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(
              tracedSVGOptions: { blackOnWhite: true }
              placeholder: TRACED_SVG
            )
          }
        }
      }
    }
  }
`;
